import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { Admin, Resource } from "react-admin";
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from "react-admin-firebase/dist/src";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AirplaneTicket from "@mui/icons-material/AirplaneTicket";
import CheckInsIcon from "@mui/icons-material/TaskAlt";
import CheckinsAdmin from "./pages/CheckinsAdmin";
import ClubInfoAdmin from "./pages/ClubInfoAdmin";
import { ClubLayout } from "./components/ClubLayout";
import CustomLoginPage from "./components/CustomLoginPage";
import Dashboard from "./pages/Dashboard";
import DiscountIcon from "@mui/icons-material/Discount";
import DiscountsAdmin from "./pages/DiscountsAdmin";
import EventIcon from "@mui/icons-material/Event";
import EventInstancesAdmin from "./pages/EventInstancesAdmin";
import EventMonitor from "./components/EventMonitor";
import EventsAdmin from "./pages/EventsAdmin";
import { LocalizationProvider } from "@mui/x-date-pickers";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MembersAdmin from "./pages/MembersAdmin";
import MembersIcon from "@mui/icons-material/SupervisedUserCircle";
import MonthlyEventsReport from "./pages/MonthlyEventsReport";
import OrdersAdmin from "./pages/OrdersAdmin";
import OrdersIcon from "@mui/icons-material/PointOfSale";
import PassAdmin from "./pages/PassAdmin";
import PassIcon from "@mui/icons-material/Loyalty";
import PassTemplatesAdmin from "./pages/PassTemplatesAdmin";
import ProductsAdmin from "./pages/ProductsAdmin";
import ProductsIcon from "@mui/icons-material/ShoppingCart";
import Storefront from "@mui/icons-material/Storefront";
import VendorsAdmin from "./pages/VendorAdmin";
import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyDUiwqz1GD3XEbGnhjc6WizsDeiRGmUdu8",
  authDomain: "manageyourclub-973b2.firebaseapp.com",
  projectId: "manageyourclub-973b2",
  storageBucket: "manageyourclub-973b2.appspot.com",
  messagingSenderId: "1010568760739",
  appId: "1:1010568760739:web:fc75d4a25be1332d189e16",
  measurementId: "G-S1YWETF3XM",
};

const club_id = "cola-stl";

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage(firebaseApp);

const authProvider = FirebaseAuthProvider(firebaseConfig, {});
const dataProvider = FirebaseDataProvider(firebaseConfig, {
  logging: true,
  rootRef: `clubs/${club_id}/`,
  app: firebaseApp,
  persistence: "local",
  disableMeta: true,
  transformToDb(
    _resourceName: any,
    documentData: { [x: string]: any },
    documentId: any
  ) {
    Object.keys(documentData).forEach(function (key) {
      if (typeof documentData[key] === typeof undefined) {
        delete documentData[key];
      }
      if (key.endsWith("_gte") || key.endsWith("_lte")) {
        delete documentData[key];
      }
    });
    return documentData;
  },
});

const theme = {
  palette: {
    mode: "light",
    primary: {
      main: "#2563eb", // Modern blue
      light: "#60a5fa",
      dark: "#1d4ed8",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#64748b", // Slate
      light: "#94a3b8",
      dark: "#475569",
      contrastText: "#ffffff",
    },
    background: {
      default: "#f8fafc",
      paper: "#ffffff",
    },
    error: {
      main: "#ef4444",
      light: "#f87171",
      dark: "#dc2626",
    },
    warning: {
      main: "#f59e0b",
      light: "#fbbf24",
      dark: "#d97706",
    },
    info: {
      main: "#3b82f6",
      light: "#60a5fa",
      dark: "#2563eb",
    },
    success: {
      main: "#10b981",
      light: "#34d399",
      dark: "#059669",
    },
    grey: {
      50: "#f8fafc",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cbd5e1",
      400: "#94a3b8",
      500: "#64748b",
      600: "#475569",
      700: "#334155",
      800: "#1e293b",
      900: "#0f172a",
    },
    text: {
      primary: "#0f172a",
      secondary: "#475569",
      disabled: "#94a3b8",
    },
    divider: "#e2e8f0",
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: 1.2,
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.3,
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: 1.4,
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: 1.4,
    },
    h5: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    h6: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.5,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: 1.5,
    },
    button: {
      textTransform: "none",
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          fontWeight: 500,
          padding: "8px 16px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
  },
};

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Admin
        title={`ManageYour.Club - The Cola St. Louis`}
        dashboard={Dashboard}
        loginPage={CustomLoginPage}
        layout={ClubLayout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        theme={theme}
      >
        <Resource name="members" icon={MembersIcon} {...MembersAdmin} />
        <Resource name="events" icon={EventIcon} {...EventsAdmin} />
        <Resource name="eventInstances" {...EventInstancesAdmin} />
        <Resource name="orders" icon={OrdersIcon} {...OrdersAdmin} />
        <Resource name="passes" icon={PassIcon} {...PassAdmin} />
        <Resource name="checkins" icon={CheckInsIcon} {...CheckinsAdmin} />
        <Resource name="vendors" icon={Storefront} {...VendorsAdmin} />
        <Resource
          name="config/v1/info"
          options={{ label: "Club Info" }}
          icon={LocationOnIcon}
          {...ClubInfoAdmin}
        />
        <Resource
          name="config/v1/discounts"
          options={{ label: "Discounts" }}
          icon={DiscountIcon}
          {...DiscountsAdmin}
        />
        <Resource
          name="config/v1/products"
          options={{ label: "Products" }}
          icon={ProductsIcon}
          {...ProductsAdmin}
        />
        <Resource
          name="config/v1/passtemplates"
          options={{ label: "Pass Templates" }}
          icon={AirplaneTicket}
          {...PassTemplatesAdmin}
        />
        <Resource
          name="monthlyEventsReport"
          options={{ label: "Monthly Events" }}
          list={MonthlyEventsReport}
        />
        {/* <Resource icon={QuestionIcon} name="questions" {...QuestionsAdmin} />
      <Resource icon={AnswersIcon} name="answers" {...AnswersAdmin} /> */}

        <EventMonitor />
      </Admin>
    </LocalizationProvider>
  );
}

export default App;
